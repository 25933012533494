<template>
	<div>
		<a-spin :spinning="loading">
			<a-row>
				<a-col :span="18">
					<a-button @click="onSearchBtn">
						<template #icon><Icon icon="SearchOutlined"/></template>
						查询</a-button>
					<!-- <exportModal class="ui-exportBrn" v-permission="['pc_system_unit_export']" :type="type" :searchData="selIdsData" :condition="condition" :role="role" :isAll="1">
						<a-button>
							<template #icon><Icon icon="UploadOutlined"/></template>
							导出</a-button>
					</exportModal>
					<exportModal class="ui-exportBrn" v-permission="['pc_system_unit_export']" style="margin-right: 16px;" :type="type" :searchData="searchData" :condition="condition" :role="role" :isAll="0">
						<a-button>
							<template #icon><Icon icon="LogoutOutlined"/></template>
							导出全部</a-button>
					</exportModal> -->
					<!-- <a-button v-permission="['pc_system_unit_export']">
						<template #icon><Icon icon="UploadOutlined"/></template>
						导出</a-button>
					<a-button v-permission="['pc_system_unit_export']">
						<template #icon><Icon icon="LogoutOutlined"/></template>
						导出全部</a-button> -->
					<a-button @click="onSearch">
						<template #icon><Icon icon="ReloadOutlined"/></template>
						刷新</a-button>
					<!-- <a-button @click="onSort">
						<template #icon><Icon icon="OrderedListOutlined"/></template>
						排序</a-button> -->
				</a-col>
			</a-row>
			
			<div v-show="isSearch" class="ui-form">
				<a-form class="label-width" ref="formRef" :model="formModal" layout="inline">
					<a-form-item label="任务名称" name="title" class="ui-form__item">
						<a-input v-model:value="formModal.title" placeholder="请输入任务名称"></a-input>
					</a-form-item>
					<a-form-item label="操作人姓名" name="userName" class="ui-form__item">
						<a-input v-model:value="formModal.userName" placeholder="请输入操作人姓名"></a-input>
					</a-form-item>
					<div style="text-align: center;">
						<a-button @click="onReset">重置</a-button>
						<a-button type="primary" @click="onSearch">搜索</a-button>
					</div>
				</a-form>
			</div>
			
			<!-- <div v-show="isSort" class="ui-form" style="padding-bottom: 20px;">
				<a-row style="align-items: center;">
					<a-col :span="18">
						<a-checkbox-group>
							<a-checkbox >单位名称</a-checkbox>
						</a-checkbox-group>
					</a-col>
					<a-col :span="6" style='text-align: right;'>
						<a-button type="primary">正序</a-button>
					</a-col>
				</a-row>
			</div> -->
			
			<div style="margin-top: 20px;">
				<c-Table ref="cTable" :columns="columns" :isRequest="true" :requestFun="getDataFun" :searchData="searchData" rowKey="id" @rowSelected="rowSelected" :scroll="{ x: 1500 }" bordered draggable>
					<template #bodyCell="{ column, record, index, pagination }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'status'">
							<div v-if="record.status === 0">就绪</div>
							<div v-if="record.status === 1">进行中</div>
							<div v-if="record.status === 2">完成</div>
							<div v-if="record.status === 3">异常</div>
						</template>
						<template v-if="column.key === 'fileStatus'">
							<div v-if="record.fileStatus === 0">未生成</div>
							<div v-if="record.fileStatus === 1">可下载</div>
							<div v-if="record.fileStatus === 2">已清除</div>
						</template>
						<template v-if="column.key === 'type'">
							<div v-if="record.type === 'assetsStrategy'">资产信息</div>
							<div v-if="record.type === 'transferByUserStrategy'">资产转移</div>
							<div v-if="record.type === 'leadAndBorrowStrategy'">借入借出</div>
							<div v-if="record.type === 'assetsTransferStrategy'">资产调拨</div>
							<div v-if="record.type === 'adminKeepAccountsStrategy'">资产下账</div>
							<!-- 与文档命名不一致 -->
							<div v-if="record.type === 'assetsScrapStrategy'">资产报废</div>
							<div v-if="record.type === 'inventoryTaskStrategy'">盘点任务</div>
							<div v-if="record.type === 'inventoryDispatchStrategy'">盘点进度</div>
							<div v-if="record.type === 'inventoryLossStrategy'">盘亏管理</div>
							<div v-if="record.type === 'inventoryProfitStrategy'">盘盈管理</div>
							<div v-if="record.type === 'inventoryResultStrategy'">盘点结果</div>
							<div v-if="record.type === 'warningStrategy'">到期预警/盘亏预警</div>
							<!-- 与文档命名不一致 -->
							<div v-if="record.type === 'fileMangerStrategy'">文件管理</div>
							<div v-if="record.type === 'roleMangerStrategy'">角色管理</div>
							<div v-if="record.type === 'userMangerStrategy'">用户管理</div>
							<div v-if="record.type === 'unitMangerStrategy'">单位管理</div>
							<div v-if="record.type === 'deptMangerStrategy'">部门管理</div>
							<div v-if="record.type === 'logMangerStrategy'">日志管理</div>
							<div v-if="record.type === 'remarkMangerStrategy'">意见管理</div>
							<div v-if="record.type === 'noticeMangerStrategy'">通知公告</div>
							<div v-if="record.type === 'personMangerStrategy'">人员管理</div>
							<div v-if="record.type === 'argsMangerStrategy'">参数设置</div>
							<div v-if="record.type === 'projectMessageExportStrategy'">在建工程</div>
							<div v-if="record.type === 'projectRequestExportStrategy'">工程日志</div>
							<div v-if="record.type === 'projectLoggerExportStrategy'">修改申请</div>
						</template>
						<template v-if="column.key === 'finishTime'">
							{{ transDateTime(record.finishTime)}}
						</template>
						<template v-if="column.key === 'action'">
							<!-- <a-button type="link" size="small" v-if="record.fileStatus === 1" @click="onDownload(record)">下载文件</a-button> -->
							<a-button type="link" size="small" v-if="record.fileStatus === 1" :href="record.filePath">下载文件</a-button>
							<div v-else>-</div>
						</template>
					</template>
				</c-Table>
				
				<!-- <a-table rowKey="id" class="table"
					:columns="columns" 
					:dataSource="list" 
					:pagination="pagination" bordered 
					size="small"
					:scroll="{ x: 1500 }"
				>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'status'">
							<div v-if="record.status === 0">就绪</div>
							<div v-if="record.status === 1">进行中</div>
							<div v-if="record.status === 2">完成</div>
							<div v-if="record.status === 3">异常</div>
						</template>
						<template v-if="column.key === 'fileStatus'">
							<div v-if="record.fileStatus === 0">未生成</div>
							<div v-if="record.fileStatus === 1">可下载</div>
							<div v-if="record.fileStatus === 2">已清除</div>
						</template>
						<template v-if="column.key === 'finishTime'">
							{{ transDateTime(record.finishTime)}}
						</template>
						<template v-if="column.key === 'action'">
							<a-button type="link" size="small" v-if="record.fileStatus === 1" @click="onDownload(record)">下载文件</a-button>
							<div v-else></div>
						</template>
					</template>
				</a-table> -->
			</div>
		</a-spin>
		<!-- <saveModal ref="saveModalRef" @refresh="onReset"></saveModal> -->
	</div>
</template>

<script>
	import { Icon } from '@/components/icon/icon.js';
	// import saveModal from './components/saveModal.vue';
	import { getTaskExportList } from '@/service/modules/importAndExport.js';
	import cTable from '@/components/cTable/index.vue';
	import exportModal from '@/components/exportModal/exportModal.vue';
	export default {
		components: { Icon, cTable, exportModal },
		data() {
			return {
				loading: false,
				isSearch: false,
				isSort: false,
				isExpand: false,
				searchData: {},
				formModal: {
					// name: ''
				}, 
				type: '', // 导出类型 任务中心 缺
				role: 1, // 导出角色
				condition: '3', // 导出条件
				selIdsData: {},
				getDataFun: getTaskExportList,
				list: [],
				columns: [{
					title: '行号',
					align: 'center',
					key: "index",
					width: 80
				}, {
					title: '任务名',
					align: 'center',
					dataIndex: "title"
				}, {
					title: '导出类型',
					align: 'center',
					key: 'type',
					dataIndex: "type"
				}, {
					title: '操作人姓名',
					align: 'center',
					dataIndex: "userName"
				}, {
					title: '任务状态',
					align: 'center',
					key: 'status',
					dataIndex: "status"
				}, {
					title: '文件状态',
					align: 'center',
					key: 'fileStatus',
					dataIndex: "fileStatus"
				}, {
					title: '完成时间',
					align: 'center',
					key: 'finishTime',
					dataIndex: "finishTime"
				},{
					title:'操作',
					align: 'center',
					key: 'action',
					fixed: 'right',
					width: 130
				}],
			}
		},
		created() {
			this.onSearch()
		},
		methods: {
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formModal));	
				// this.pagination.current = 1;
				// console.log("sear", this.searchData,this.formModal.name)
				// this.getData();
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			onReset() {
				this.$refs.formRef.resetFields();
				this.formModal = {
					// name: ''
				}
				this.searchData = {}
				this.onSearch();
			},
			// async getData() {
			// 	this.loading = true;
			// 	try {
			// 		let ret = await getTaskExportList({
			// 			page: this.pagination.current,
			// 			pageSize: this.pagination.pageSize,
			// 			...this.searchData
			// 		});
			// 		this.loading = false;
			// 		if (ret.code === 200) {
			// 			this.list = ret.data.list;
			// 			this.pagination.total = ret.data.totalCount;
			// 			console.log("导入任务列表",this.list)
			// 		}
			// 	} catch(e) {
			// 		this.loading = false;
			// 	}
			// },
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			onSort() {
				this.isSort = !this.isSort;
			},
			// onDownload(item) {
			// 	console.log("返回的数据",item)
			// 	this.$download(item.filePath);
			// },
			rowSelected(item) {
				this.selIdsData.ids = item.keys.join(',')
			}
			// onAdd() {
			// 	this.$refs.saveModalRef.open(0);
			// },
			// onEdit(item) {
			// 	console.log(item)
			// 	this.$refs.saveModalRef.open(item);
			// },
			// onDelete(item) {
			// 	this.$confirm({
			// 		title: '提示',
			// 		content: "确定删除吗？",
			// 		onOk: async ()=> {
			// 			this.loading = true;
			// 			try {
			// 				let ret = await deleteUnit({
			// 					id: item.id
			// 				})
			// 				this.loading = false;
			// 				if (ret.code === 200) {
			// 					this.$message.success('删除成功');
			// 					this.getData();
			// 				}
			// 			} catch(e) {
			// 				this.loading = false;
			// 			}
			// 		}
			// 	})
			// }
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 20px;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		text-align: center;
	}
	.ui-exportBrn {
		margin-left: 16px;
	}
</style>